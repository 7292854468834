import axios from 'axios'
import { storageService } from './storage.service'

const baseUrl = storageService.setApiUrl()

class JambFileManagement{
	apiUrl = baseUrl

	async fetchSubject () {
		const apiToken = await storageService.getToken()
		return await axios.get(`${baseUrl}/load-subject`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async createSubject (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/create-subject`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateSubject (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/update-subject`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteSubject (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/delete-subject`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async createYear (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/create-examyear`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateYear (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/update-examyear`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteYear (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/delete-examyear`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async fetchPastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/load-jamb-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async fetchSinglePastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/load-single-jamb-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async storePastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/store-jamb-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updatePastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/update-jamb-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deletePastQuestionImage (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/delete-jamb-past-question-image`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deletePastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/delete-jamb-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async uploadQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/upload-jamb-past-question-file`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}
}

const jambFileMgt = new JambFileManagement()
export { jambFileMgt }